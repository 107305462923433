import React from "react";
import { List, ListItem, ListItemIcon, ListItemText, Divider } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import DescriptionIcon from "@material-ui/icons/Description";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GroupIcon from "@material-ui/icons/Group";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { Link } from "react-router-dom";

const Listbar = ({ cambiarEstado, user }) => {
    if (user.rol && user.rol === "Cliente") {
        return (
            <div>
                <List component="nav">
                    <ListItem button component={Link} to="/inicio">
                        <ListItemIcon>
                            <HomeIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Inicio" />
                    </ListItem>

                    <ListItem button component={Link} to="expediente">
                        <ListItemIcon>
                            <DescriptionIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Expedientes" />
                    </ListItem>

                    <br />
                    <br />
                    <br />
                    <Divider style={{ background: "gray" }} />

                    <ListItem
                        button
                        onClick={() => {
                            cambiarEstado();
                            localStorage.removeItem("token");
                        }}
                        component={Link}
                        to="/"
                    >
                        <ListItemIcon>
                            <ExitToAppIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Desconectar" />
                    </ListItem>

                    <Divider />

                    <ListItem style={{marginTop : "auto"}}>
                        <ListItemIcon>
                            <ExitToAppIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Desconectar" />
                    </ListItem>
                </List>
            </div>
        );
    } else if (user.rol && user.rol === "Auditor") {
        return (
            <div>
                <List component="nav">
                    <ListItem button component={Link} to="/inicio">
                        <ListItemIcon>
                            <HomeIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Inicio" />
                    </ListItem>

                    <ListItem button component={Link} to="/auditoria">
                        <ListItemIcon>
                            <LibraryAddCheckIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Auditorías" />
                    </ListItem>

                    <br />
                    <br />
                    <br />
                    <Divider style={{ background: "gray" }} />

                    <ListItem
                        button
                        onClick={() => {
                            cambiarEstado();
                            localStorage.removeItem("token");
                        }}
                        component={Link}
                        to="/"
                    >
                        <ListItemIcon>
                            <ExitToAppIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Desconectar" />
                    </ListItem>

                    <Divider />
                </List>
            </div>
        );
    } else {
        return (
            <div style={{height: "100%"}}>
                <List component="nav" style={{height: "100%"}}>
                    <ListItem button component={Link} to="/inicio">
                        <ListItemIcon>
                            <HomeIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Inicio" />
                    </ListItem>

                    <ListItem button component={Link} to="/usuario">
                        <ListItemIcon>
                            <GroupIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Usuarios" />
                    </ListItem>

                    <ListItem button component={Link} to="/empresa">
                        <ListItemIcon>
                            <BusinessCenterIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Empresas" />
                    </ListItem>

                    <ListItem button component={Link} to="/evaluacion">
                        <ListItemIcon>
                            <FileCopyIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Expedientes" />
                    </ListItem>

                    <ListItem button component={Link} to="/auditoria">
                        <ListItemIcon>
                            <LibraryAddCheckIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Auditorías" />
                    </ListItem>

                    <br />
                    <br />
                    <br />
                    <Divider style={{ background: "gray" }} />

                    <ListItem
                        button
                        onClick={() => {
                            cambiarEstado();
                            localStorage.removeItem("token");
                        }}
                        component={Link}
                        to="/"
                    >
                        <ListItemIcon>
                            <ExitToAppIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Desconectar" />
                    </ListItem>

                    <Divider />

                    <ListItem component="a" target="_blank" href="https://www.lotinternacional.com/lotinternacional" style={{ color: "white" , position: "absolute",
                        top: "5", bottom: "0"}}>
                        <ListItemText primary="© LOT" />
                    </ListItem>
                </List>
            </div>
        );
    }
};

export default Listbar;
